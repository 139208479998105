export default [
  {
    key: 'betToolEn',
    label: 'field.nameEn',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'betToolKh',
    label: 'field.nameKh',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'status',
    label: 'status.active',
    type: 'checkbox',
    cols: 'auto',
  },
]
